
import { areaData, pointIcon } from './map-utils'

export const methods = {
  // 转化下标
  fmtIconIdx(idx) {
    let iconIdx = ['one', 'two', 'three', 'four', 'five']
    if (idx === 0) {
      return iconIdx[0]
    } else if (idx > 0 && idx <= 2) {
      return iconIdx[0]
    } else if (idx > 2 && idx <= 5) {
      return iconIdx[2]
    } else if (idx > 5 && idx <= 9) {
      return iconIdx[3]
    } else if (idx > 9) {
      return iconIdx[4]
    }
  },
  // 区域遮罩 + 大点 和点击事件 + 名字获取省市坐标 + 根据位置数组自动调整显示中心
  // https://lbs.baidu.com/jsdemo.htm#kMarker3dAddEvent 演示地址
  doProvince2Area() {
    var citys = [
      '济南市',
      '青岛市',
      '烟台市',
      '潍坊市',
      '淄博市',
      '威海市',
      '济宁市',
      '东营市',
      '泰安市',
      '德州市',
      '聊城市',
      '滨州市',
      '枣庄市',
      '菏泽市',
      '日照市',
      '莱芜市',
      '临沂市',
    ]
    var bdary = new BMapGL.Boundary()
    bdary.get('山东省', function (rs) {
      // 绘制行政区
      map.clearOverlays()
      var count = rs.boundaries.length
      if (count === 0) {
        alert('未能获取当前输入行政区域')
        return
      }
      var pointArray = []
      for (var i = 0; i < count; i++) {
        var ply = new BMapGL.Polygon(rs.boundaries[i], {
          strokeWeight: 1,
          strokeColor: '#f0f',
        }) // 建立多边形覆盖物
        map.addOverlay(ply)
        pointArray = pointArray.concat(ply.getPath())
      }
      map.setViewport(pointArray) // 调整视野
      // 绘制带高度的点
      var cityGeo = new BMapGL.Geocoder()
      for (let i = 0; i < citys.length; i++) {
        cityGeo.getPoint(citys[i], function (point) {
          // 地址解析获取对应经纬度
          var pt = new BMapGL.Point(point.lng, point.lat)
          var marker = new BMapGL.Marker3D(
            pt,
            Math.round(Math.random()) * 130000,
            {
              size: 50,
              shape: 'BMAP_SHAPE_CIRCLE',
              fillColor: '#454399',
              fillOpacity: 0.6,
            }
          )
          // 添加鼠标事件
          marker.addEventListener('mouseover', function (e) {
            e.target.setFillColor('#f00')
          })
          marker.addEventListener('mouseout', function (e) {
            e.target.setFillColor('#454399')
          })
          marker.addEventListener('click', function (e) {
            alert('click!')
          })
          map.addOverlay(marker)
        })
      }
    })
  },
  // 当前放大区域的细化位点
  showCurAreaP(curIdx, dataList) {
    let that = this
    let map = this.map
    let cityGeo = new BMapGL.Geocoder()
    let points = dataList[curIdx].map(ele => ele.detailedAddress)

    // points = [{ lng: 110.22697486248094, lat: 21.38916736697424 }, { lng: 110.29333969543097, lat: 21.4024324075778 }, { lng: 110.26986920572914, lat: 21.35277700983042 }, { lng: 110.22778418971203, lat: 21.35277700983042 }]
    for (let i = 0; i < points.length; i++) {
      cityGeo.getPoint(points[i], function (point) {
        // 地址解析获取对应经纬度
        let pt = new BMapGL.Point(point.lng, point.lat)
        let marker = new BMapGL.Marker(pt, {
          icon: that.whitePointIcon,
        })
        // marker.disableMassClear()
        map.addOverlay(marker)
        that.$route.name === 'feedProcessing' ?
          that.twelveAreaWindow(marker, { name: dataList[curIdx][i].companyName, content: dataList[curIdx][i].companyIntroduction }, pt, 'click')
          : that.addInfoWindow(marker, dataList[curIdx][i]) // 添加跟随弹窗

        // 创建文本标注对象
        let label = new BMapGL.Label(i * i, {
          position: pt,
          offset: new BMapGL.Size(that.app.fontHandle(-125), that.app.fontHandle(-34)), // 设置文本偏移量
        })
        // 自定义文本标注样式
        label.setStyle({
          width: '2.5rem',
          background: 'none',
          color: '#fff',
          borderWidth: '0',
          padding: '0rem',
          fontSize: '0.16rem',
          fontFamily: '微软雅黑',
        })
        label.setContent(`<div class="area-point-num">${dataList[curIdx][i].companyName}</div>`)
        map.addOverlay(label)

        marker.addEventListener('click', function (e) {
          setTimeout(() => {//11.6
            map.flyTo(pt, 13.293934640495166)
          }, 300);
        })
      })
    }
  },
  // 带高度的点 **
  // https://lbs.baidu.com/jsdemo.htm#eMarker3d
  haveHeightPoint(dataList, _Icon, clickCall, secondData) {
    // console.log('fasdf', dataList, _Icon, isLabelMassClear, this.citys)
    let that = this
    let map = this.map
    // 创建位点图标
    this.whitePointIcon = new BMapGL.Icon(
      _Icon || require('../../../assets/img/large-screen/icon-point.png'),
      _Icon ? new BMapGL.Size(that.app.fontHandle(24), that.app.fontHandle(24)) : new BMapGL.Size(that.app.fontHandle(23), that.app.fontHandle(27))
    )
    let citys = this.citys

    // 获取地址字符串对应的 经纬度
    let cityGeo = new BMapGL.Geocoder()
    for (let i = 0; i < citys.length; i++) {
      cityGeo.getPoint(citys[i], function (point) {
        // 地址解析获取对应经纬度
        var pt = new BMapGL.Point(point.lng, point.lat)
        // console.log(citys[i], pt)
        let marker = new BMapGL.Marker(pt, {
          icon: that.whitePointIcon,
        })
        marker.disableMassClear()
        map.addOverlay(marker)
        // that.addInfoWindow(marker, i) // 添加跟随弹窗
        that.citys_overlays.push(marker)

        // 创建文本标注对象
        let label = new BMapGL.Label(i * i, {
          position: pt,
          offset: new BMapGL.Size(that.app.fontHandle(!secondData ? -125 : -15), that.app.fontHandle(-34)), // 设置文本偏移量
        })
        // 自定义文本标注样式
        label.setStyle({
          width: '0.3rem',
          background: 'none',
          color: '#fff',
          borderWidth: '0',
          padding: '0rem',
          fontSize: '0.16rem',
          fontFamily: '微软雅黑',
        })
        label.setContent(`<div class="area-point-num">${dataList ? dataList[i] : i}</div>`)
        label.disableMassClear()
        map.addOverlay(label)
        that.citys_overlays.push(label)

        if (!secondData) {
          label.setStyle({
            width: '2.5rem',
            background: 'none',
            color: '#fff',
            borderWidth: '0',
            padding: '0rem',
            fontSize: '0.16rem',
            fontFamily: '微软雅黑',
          })
          secondData && that.addInfoWindow(marker, { key: i, name: dataList[i], point })
        }

        marker.addEventListener('click', function (e) {
          // console.log('clickEvts', '点了第几个图标', i)
          if (clickCall) {
            return clickCall(i)
          }

          setTimeout(() => {
            map.flyTo(pt, 12.506626854723839)
            secondData && that.showCurAreaP(i, secondData)
          }, 300);
        })
      })
    }
  },
  // 首次-进行自定义绘制
  seaArea(parName) {
    let map = this.map
    let that = this
    this.showTxt2Map = false
    // 缩放事件监听
    map.addEventListener('zoomend', function () {

      setTimeout(() => {
        console.log('initzoom', that.initZoom, map.getZoom())
        if (map.getZoom() >= (that.initZoom + 1.2)) {
          !that.showTxt2Map && that.editTxt2Map(parName)
          that.showTxt2Map = true
        } else {
          that.showTxt2Map = false
          map.clearOverlays()
        }
      }, 400);
    })

    var pointArray = []
    var count = areaData.length

    for (let i = 0; i < count; i++) {
      var ply = new BMapGL.Polygon(areaData[i], {
        strokeWeight: 1,
        strokeColor: '#ff00ff',
      }) // 建立多边形覆盖物
      // map.addOverlay(ply)
      pointArray = pointArray.concat(ply.getPath())
    }
    pointArray = pointArray.concat(ply.getPath())
    // console.log('setViewport')
    //  园区概况缩放参数 zoomFactor: -0.99,margins: [10, 120, 0, 300]
    map.setViewport(pointArray, {
      zoomFactor: parName === 'subpages' ? -5.65 : -0.35, // 	地图级别的偏移量，您可以在方法得出的结果上增加一个偏移值。例如map.setViewport计算出地图的级别为10，如果zoomFactor为-1，则最终的地图级别为9
      margins: parName === 'subpages' ? [0, 20, 0, 0] : [100, 300, 174, 300], // 视野调整的预留边距，例如： margins: [30, 20, 0, 20] 表示坐标点会限制在上述区域内
    }) // 调整视野

    that.initZoom = map.getZoom()

    ///////////////////// 地图标点
    for (let i = 0; i < pointIcon.length; i++) {
      let pp = pointIcon[i].point.split(',')
      let position = new BMapGL.Point(pp[0], pp[1])

      let label = null
      if (!(i < 3 && i > 0)) {
        if (i > 9) {
          // 添加标注文字
          let opts2 = {
            position, // 指定文本标注所在的地理位置
            offset: new BMapGL.Size(that.app.fontHandle(-10), that.app.fontHandle(-10)), // 设置文本偏移量
          }
          // 创建文本标注对象2
          let label2 = new BMapGL.Label(i - 9, opts2)
          // 自定义文本标注样式
          label2.setStyle({
            background: 'none',
            color: '#fff',
            borderRadius: '50%',
            border: '0.02rem solid #fff',
            padding: '0rem',
            fontSize: '0.12rem',
            height: '0.2rem',
            lineHeight: '0.2rem',
            width: '0.2rem',
            textAlign: 'center',
            fontFamily: 'Microsoft YaHei'
          })
          label2.disableMassClear()
          map.addOverlay(label2)
          label = label2
          this.twelve_area_overlays.push(label)
        } else {
          // 创建图标
          var marker = new BMapGL.Marker(position, {
            icon: new BMapGL.Icon(
              require(`../../../assets/img/large-screen/icon-map-${this.fmtIconIdx(i)}.png`),
              new BMapGL.Size(that.app.fontHandle(23), that.app.fontHandle(23))
            )
          })
          map.addOverlay(marker)
          marker.disableMassClear()
          label = marker
        }
      }
    }

  },
  // 缩放操作-增加文本到地图上
  editTxt2Map(page) {
    let map = this.map
    ///////////////////// 地图标点
    for (let i = 0; i < pointIcon.length; i++) {
      if (i > 9) return
      let pp = pointIcon[i].point.split(',')
      let position = new BMapGL.Point(pp[0], pp[1])
      // 添加标注文字
      var opts = {
        position, // 指定文本标注所在的地理位置
        offset: new BMapGL.Size(this.app.fontHandle(-125), this.app.fontHandle(-38)), // 设置文本偏移量
      }
      // 创建文本标注对象
      var label = new BMapGL.Label(pointIcon[i].name, opts)
      // 自定义文本标注样式
      label.setStyle({
        width: '2.5rem',
        background: 'none',
        color: '#fff',
        borderRadius: '0.05rem',
        borderWidth: '0',
        padding: '0rem',
        fontSize: i < 3 && i > 0 ? '0.3rem' : '0.16rem',
        height: '0.1rem',
        lineHeight: '0.3rem',
        fontFamily: '微软雅黑',
      })
      page === 'subpages' && label.setStyle({
        width: '250px',
        background: 'none',
        color: '#fff',
        borderRadius: '5px',
        borderWidth: '0',
        padding: '0px',
        fontSize: i < 3 && i > 0 ? '30px' : '16px',
        height: '10px',
        lineHeight: '30px',
        fontFamily: '微软雅黑',
      })
      label.setContent(`<div class="area-point-num">${pointIcon[i].name}</div>`)
      map.addOverlay(label)
    }
  },
  // 12片区的操作展示
  twelveAreaMethod(data, isClick, windowData) {
    let map = this.map
    ///////////////////// 地图标点
    for (let i = 0; i < pointIcon.length; i++) {
      if (i <= 9) continue
      let pp = pointIcon[i].point.split(',')
      let position = new BMapGL.Point(pp[0], pp[1])
      // 添加标注文字
      var opts = {
        position, // 指定文本标注所在的地理位置
        offset: new BMapGL.Size(this.app.fontHandle(-125), this.app.fontHandle(-38)), // 设置文本偏移量
      }
      // 创建文本标注对象
      var label = new BMapGL.Label(data[i - 10].boxNumber, opts)
      // 自定义文本标注样式
      label.setStyle({
        width: '2.5rem',
        background: 'none',
        color: '#fff',
        borderRadius: '0.05rem',
        borderWidth: '0',
        padding: '0rem',
        fontSize: i < 3 && i > 0 ? '0.3rem' : '0.16rem',
        height: '0.1rem',
        lineHeight: '0.3rem',
        fontFamily: '微软雅黑',
      })
      let cur = data[i - 10]
      // let eventType = this.$route.name === 'digitalPark' ? 'click' : 'mouseover'
      if (this.$route.name === 'digitalPark') {
        label.setContent(`<div class="area-point-num"><span class="child-point-num">${cur.temp}℃</span></div>`)
        label.disableMassClear()
        map.addOverlay(label)
      }

      windowData && this.introductBoxWindow(this.twelve_area_overlays[i - 10], cur, position, 'mouseover')

      this.citys_overlays.push(label)
    }
  },
  // 切换地图模式
  addChangeType() {
    let map = this.map
    //定义一个控件类
    function ZoomControl() {
      this.defaultAnchor = BMAP_ANCHOR_BOTTOM_RIGHT
      this.defaultOffset = new BMapGL.Size(15 + 704, 92 + 153)
    }
    //通过JavaScript的prototype属性继承于BMap.Control
    ZoomControl.prototype = new BMapGL.Control()

    //自定义控件必须实现自己的initialize方法，并且将控件的DOM元素返回
    //在本方法中创建个div元素作为控件的容器，并将其添加到地图容器中
    ZoomControl.prototype.initialize = function (map) {
      //创建一个dom元素
      var div = document.createElement('div')
      //添加文字说明
      div.appendChild(document.createTextNode('卫星'))
      // 设置样式
      div.style.cursor = 'pointer'
      div.style.backgroundColor = 'white'
      // 绑定事件,点击切换地图央视
      div.onclick = function (e) {
        console.log('点击切换地图央视', e)
        map.setMapType(
          map.getMapType() === BMAP_EARTH_MAP
            ? BMAP_NORMAL_MAP
            : BMAP_EARTH_MAP
        )
        e.target.innerText = e.target.innerText === '卫星' ? '平面' : '卫星'
      }
      // 添加DOM元素到地图中
      map.getContainer().appendChild(div)
      // 将DOM元素返回
      return div
    }
    //创建控件元素
    var myZoomCtrl = new ZoomControl()
    //添加到地图中
    map.addControl(myZoomCtrl)
  },

  // 自定义控件 doing
  // https://lbsyun.baidu.com/jsdemo.htm#cAddCustomControl
  customeControlClass() {
    let map = this.map
    let that = this
    //定义一个控件类
    function ZoomControl() {
      this.defaultAnchor = BMAP_ANCHOR_BOTTOM_RIGHT
      this.defaultOffset = new BMapGL.Size(that.app.fontHandle(639), that.app.fontHandle(215))
    }
    //通过JavaScript的prototype属性继承于BMap.Control
    ZoomControl.prototype = new BMapGL.Control()

    //自定义控件必须实现自己的initialize方法，并且将控件的DOM元素返回
    //在本方法中创建个div元素作为控件的容器，并将其添加到地图容器中
    ZoomControl.prototype.initialize = function (map) {
      that.zoomSize = 0
      //创建一个dom元素
      var div = document.createElement('div')
      //添加文字说明
      // div.appendChild(document.createTextNode('返回'))
      // 设置样式
      div.className = 'map-custome-btn'

      let goback = document.createElement('div')
      goback.className = 'map-goback map-btn-item'
      let add = document.createElement('div')
      add.className = 'map-add map-btn-item'
      let dec = document.createElement('div')
      dec.className = 'map-dec map-btn-item'
      let type = document.createElement('div')
      type.className = 'map-type map-btn-item'
      type.appendChild(document.createTextNode('卫星'))
      //  追加
      div.appendChild(goback)
      div.appendChild(add)
      div.appendChild(dec)
      div.appendChild(type)

      // 绑定事件
      type.onclick = function (e) {
        console.log('点击切换地图央视', e)
        map.setMapType(
          map.getMapType() === BMAP_EARTH_MAP
            ? BMAP_NORMAL_MAP
            : BMAP_EARTH_MAP
        )
        e.target.innerText = e.target.innerText === '卫星' ? '平面' : '卫星'
      }
      add.onclick = function (e) {
        that.zoomSize += 1
        map.setZoom(map.getZoom() + 1.2)
        console.log('add', map.getZoom())
      }
      dec.onclick = function (e) {
        that.zoomSize -= 1
        // if (that.zoomSize <= 0) map.clearOverlays()

        map.setZoom(map.getZoom() - 1.2)
        console.log('dec', map.getZoom())
      }
      goback.onclick = function (e) {
        console.log('goback', map.getZoom())
        that.zoomSize = 0
        map.reset()
      }
      // 添加DOM元素到地图中
      map.getContainer().appendChild(div)
      // 将DOM元素返回
      return div
    }
    //创建控件元素
    var myZoomCtrl = new ZoomControl()
    //添加到地图中
    map.addControl(myZoomCtrl)
    this.mapIconList()
  },
  // 地图自定图标实例 doing
  mapIconList() {
    let map = this.map
    let that = this
    function ZoomControl() {
      this.defaultAnchor = BMAP_ANCHOR_BOTTOM_LEFT
      this.defaultOffset = new BMapGL.Size(that.app.fontHandle(638), that.app.fontHandle(213))
    }
    ZoomControl.prototype = new BMapGL.Control()
    ZoomControl.prototype.initialize = function (map) {
      //创建一个dom元素
      var div = document.createElement('div')
      div.id = 'map-custome-icon-ls'

      let one = document.createElement('div')
      one.className = 'map-one map-icon-item'
      one.appendChild(document.createTextNode('“一核”'))
      let two = document.createElement('div')
      two.className = 'map-two map-icon-item'
      two.appendChild(document.createTextNode('“双翼”'))
      let three = document.createElement('div')
      three.className = 'map-three map-icon-item'
      three.appendChild(document.createTextNode('“三港”'))
      let four = document.createElement('div')
      four.className = 'map-four map-icon-item'
      four.appendChild(document.createTextNode('“四心”'))
      let five = document.createElement('div')
      five.className = 'map-five map-icon-item'
      five.appendChild(document.createTextNode('“十二片区”'))
      // 追加
      div.appendChild(one)
      div.appendChild(two)
      div.appendChild(three)
      div.appendChild(four)
      div.appendChild(five)
      // 添加DOM元素到地图中
      map.getContainer().appendChild(div)
      // 将DOM元素返回
      return div
    }
    //创建控件元素
    var myZoomCtrl = new ZoomControl()
    //添加到地图中
    map.addControl(myZoomCtrl)
  },

  // 将图标添加到地图上
  // https://lbs.baidu.com/jsdemo.htm#eChangeMarkerIcon
  oneMarkerIcon() {
    let map = this.map
    var point = new BMapGL.Point(116.404, 39.915)
    map.centerAndZoom(point, 15)
    // 创建小车图标
    var myIcon = new BMapGL.Icon(
      require('../img/car.png'),
      new BMapGL.Size(52, 26)
    )
    // 创建Marker标注，使用小车图标
    var pt = new BMapGL.Point(116.417, 39.909)
    var marker = new BMapGL.Marker(pt, {
      icon: myIcon,
    })
    // 将标注添加到地图
    map.addOverlay(marker)
  },

  //  添加文字标签 自定义文本标注
  // https://lbsyun.baidu.com/jsdemo.htm#eAddLabel
  customeTextLabel() {
    var opts = {
      position: new BMapGL.Point(116.2787, 40.0492), // 指定文本标注所在的地理位置
      offset: new BMapGL.Size(30, -30), // 设置文本偏移量
    }
    // 创建文本标注对象
    var label = new BMapGL.Label('欢迎使用百度地图JSAPI GL版本', opts)
    // 自定义文本标注样式
    label.setStyle({
      color: 'blue',
      borderRadius: '0.05rem',
      borderColor: '#ccc',
      padding: '0.1rem',
      fontSize: '0.16rem',
      height: '0.3rem',
      lineHeight: '0.3rem',
      fontFamily: '微软雅黑',
    })
    map.addOverlay(label)
  },

  // 画出区域 立体显示
  // https://lbsyun.baidu.com/jsdemo.htm#webgl1_6
  checkArea() {
    map.setTilt(0)
    var bd = new BMapGL.Boundary()
    bd.get('北京市', function (rs) {
      var count = rs.boundaries.length //行政区域的点有多少个
      var pointArray = []
      for (var i = 0; i < count; i++) {
        var path = []
        str = rs.boundaries[i].replace(' ', '')
        points = str.split(';')
        for (var j = 0; j < points.length; j++) {
          var lng = points[j].split(',')[0]
          var lat = points[j].split(',')[1]
          path.push(new BMapGL.Point(lng, lat))
        }
        var prism = new BMapGL.Prism(path, 5000, {
          topFillColor: '#5679ea',
          topFillOpacity: 0.5,
          sideFillColor: '#5679ea',
          sideFillOpacity: 0.9,
        })
        map.addOverlay(prism)
      }
    })
  },

  // introductBox
  introductBoxWindow(label, data, position, eventType) {
    let that = this
    let map = this.map
    var infoWindow = new BMapGL.InfoWindow(document.querySelector('.introduct-window-area'), {
      offset: { width: that.app.fontHandle(160), height: that.app.fontHandle(200) },
      width: that.app.fontHandle(304) + 1,
      height: that.app.fontHandle(320) + 10
    })
    // label添加点击事件
    label.addEventListener(eventType || 'mouseover', function () {
      that.chartsData = data
      document.querySelector('.introduct-window-area').style.opacity = '1'
      map.openInfoWindow(infoWindow, position)
    })
  },
  // 12片区小弹窗
  twelveAreaWindow(marker, data, position, eventType) {
    let that = this
    let map = this.map
    var infoWindow = new BMapGL.InfoWindow(document.querySelector('.window-twelve-area'), {
      offset: { width: that.app.fontHandle(160), height: that.app.fontHandle(80) },
      width: that.app.fontHandle(304) + 1,
      height: that.app.fontHandle(320) + 10
    })
    // marker添加点击事件
    marker.addEventListener(eventType || 'mouseover', function () {
      that.chartsData = data
      document.querySelector('.window-twelve-area').style.opacity = '1'
      map.openInfoWindow(infoWindow, position)
    })
  },
  // 强自定义地图弹窗
  // 添加图文组合的信息窗口
  // 创建添加点标记
  addInfoWindow(marker, data) {
    let map = this.map
    let that = this

    var infoWindow = new BMapGL.InfoWindow(document.querySelector('.window-wrap'), {
      offset: { width: that.app.fontHandle(196), height: that.app.fontHandle(210) },
      width: that.app.fontHandle(404) + 1,
      height: that.app.fontHandle(420) + 10,
      overflow: 'hidden',
      backgroundColor: '#dfdae4'
    }) // width: 264 一般的宽度
    //   setContent(content: String | HTMLElement) 设置信息窗口内容。支持HTML内容。1.2版本开始content参数支持传入DOM结点
    // marker添加点击事件
    marker.addEventListener('click', function () {
      that.chartsData = data
      document.querySelector('.window-wrap').style.opacity = '1'
      this.openInfoWindow(infoWindow)
      // 图片加载完毕重绘infoWindow
      // document.getElementById('imgDemo').onload = function () {
      //   // map.reset()
      //   infoWindow.redraw() // 防止在网速较慢时生成的信息框高度比图片总高度小，导致图片部分被隐藏
      // }
    })
  },

  // 创建绘制点面线 并 批量绑定事件
  // https://lbs.baidu.com/jsdemo.htm#kOverlayAddEvent 演示
  createMarker() {
    // 绘制点
    var marker = new BMapGL.Marker(point)
    map.addOverlay(marker)

    // 绘制面
    var polygon = new BMapGL.Polygon(
      [
        new BMapGL.Point(116.387112, 39.920977),
        new BMapGL.Point(116.385243, 39.913063),
        new BMapGL.Point(116.394226, 39.917988),
        new BMapGL.Point(116.401772, 39.921364),
        new BMapGL.Point(116.41248, 39.927893),
      ],
      {
        strokeColor: 'blue',
        strokeWeight: 2,
        strokeOpacity: 0.5,
      }
    )
    map.addOverlay(polygon)

    // 绘制线
    var polyline = new BMapGL.Polyline(
      [
        new BMapGL.Point(116.399, 39.91),
        new BMapGL.Point(116.405, 39.92),
        new BMapGL.Point(116.423493, 39.907445),
      ],
      {
        strokeColor: 'blue',
        strokeWeight: 2,
        strokeOpacity: 0.5,
      }
    )
    map.addOverlay(polyline)

    // 绘制圆
    var circle = new BMapGL.Circle(new BMapGL.Point(116.404, 39.915), 500, {
      strokeColor: 'blue',
      strokeWeight: 2,
      strokeOpacity: 0.5,
    })
    map.addOverlay(circle)

    // 批量绑定事件
    var clickEvts = ['click', 'dblclick', 'rightclick']
    var moveEvts = ['mouseover', 'mouseout']
    var overlays = [marker, polyline, polygon, circle]

    for (let i = 0; i < clickEvts.length; i++) {
      const event = clickEvts[i]
      for (let j = 0; j < overlays.length; j++) {
        const overlay = overlays[j]
        overlay.addEventListener(event, (e) => {
          switch (event) {
            case 'click':
              var res = overlay.toString() + '被单击!'
              break
            case 'dbclick':
              var res = overlay.toString() + '被双击!'
              break
            case 'rightclick':
              var res = overlay.toString() + '被右击!'
          }
          alert(res)
        })
      }
    }
    for (let i = 0; i < moveEvts.length; i++) {
      const event = moveEvts[i]
      for (let j = 1; j < overlays.length; j++) {
        const overlay = overlays[j]
        overlay.addEventListener(event, (e) => {
          switch (event) {
            case 'mouseover':
              overlay.setFillColor('#6f6cd8')
              break
            case 'mouseout':
              overlay.setFillColor('#fff')
              break
          }
        })
      }
    }
  },
  // 创建点 绑定事件 传递参数
  // https://lbs.baidu.com/jsdemo.htm#kSendEventInfo 演示
  markerClickParams() {
    // 创建点
    var marker = new BMapGL.Marker(new BMapGL.Point(116.404, 39.915))
    map.addOverlay(marker)
    marker.addEventListener('click', overlayStyle)
    // 创建圆
    var circle = new BMapGL.Circle(
      new BMapGL.Point(116.415157, 39.914004),
      500,
      {
        strokeColor: 'blue',
        strokeWeight: 6,
        strokeOpacity: 0.5,
      }
    )
    map.addOverlay(circle)
    circle.addEventListener('click', overlayStyle)
    // 获取覆盖物属性
    function overlayStyle(e) {
      var p = e.target
      if (p instanceof BMapGL.Marker) {
        alert(
          '该覆盖物是点，点的坐标为：' +
          p.getPosition().lng +
          ',' +
          p.getPosition().lat
        )
      } else if (p instanceof BMapGL.Circle) {
        alert(
          '该覆盖物是圆，其半径为：' +
          p.getRadius() +
          '圆中心点坐标：' +
          p.getCenter().lng +
          ',' +
          p.getCenter().lat
        )
      } else {
        alert('无法获知该覆盖物类型')
      }
    }
  },
}